import React from 'react'
import { Row, Column, GridContainer, H1 } from '@vp/swan'
import {
  SearchBar,
  HelpResultTaxonomy,
  MyAccountResultsPlugin,
  HelpCenterResultsPlugin,
  StaticPagesResultsPlugin,
} from '@vp/omnisearch'
import { OrderTrackingLink } from './components/OrderTrackingLink'
import { useTranslation } from 'react-i18next'
import styles, { stylesheet } from './App.scss'
import { useIdentity, useStyles } from '@vp/ubik-context'
import { HelpCenterUrls } from './types/urls.type'
import { formatUrl } from './utils/url.utils'
import { formatLocale } from './utils/general.utils'
export interface AppProps {
  locale: string;
  tenant: string;
  helpCenterUrls: HelpCenterUrls
}
export const App = (props: AppProps) => {
  const { locale, tenant, helpCenterUrls } = props
  const lng = locale.toLowerCase().replace('-', '_')

  const { t } = useTranslation(tenant)
  const authIdentiy = useIdentity()
  useStyles(stylesheet)

  const queryStringConst = 'querycat=helpcenter&q='
  const queryStringDropDownConst = 'querycat=helpcenter_search_dropdown&q='

  return (
    <GridContainer>
      <Row className={styles.hcSearchHeaderContainer}>
        <Column span={12} spanXs={12}>
          <Row>
            <Column
              span={12}
              marginBottom='6'
              textAlign='center'
            >
              <H1
                fontSkin='title-headline'
              >
                {t(`${tenant}:header.search.title`, { lng })}
              </H1>
            </Column>
          </Row>
          {/* @ts-ignore */}
          <Row align='center' style={{ display: 'flex', justifyContent: 'center' }} paddingX={5}>
            <Column
              span={6}
              spanXs={12}
              spanSm={8}
              pr={0}
            >
              <div style={{ maxWidth: '490px', textAlign: 'left' }}>
                <SearchBar
                  searchLabel={t(
                      `${tenant}:header.search.placeholder`,
                      { lng }
                  )}
                  auth={authIdentiy.auth}
                  locale={formatLocale(locale)}
                    // resultsPageUrl={this.state.searchUrl}
                  seeAllText={t(
                      `${tenant}:search_results.see_all_results`,
                      { lng }
                  )}
                  applicationName='help-center-page'
                    // Optional: Change the text in the search bar
                  searchPlaceholder={t(
                      `${tenant}:header.search.placeholder`,
                      { lng }
                  )}
                  getFlyoutSiteAnalyticsQueryString={(query) =>
                      `${queryStringDropDownConst}${query}`}
                  getResultsPageUrl={(query) =>
                      `${formatUrl(helpCenterUrls.newPlatformSearchUrl)}?${queryStringConst}${query}`}
                    // Optional: Change the default result sources and ordering
                    // @ts-ignore
                  resultDisplayConfiguration={{
                    includeTaxonomies: [HelpResultTaxonomy.id],
                    maxResultsPerTaxonomy: { default: 5 },
                  }}
                  resultPlugins={[
                    MyAccountResultsPlugin(formatLocale(locale), 5),
                    HelpCenterResultsPlugin(formatLocale(locale), 5),
                    StaticPagesResultsPlugin(formatLocale(locale), 5),
                  ]}
                />
              </div>

            </Column>
          </Row>
          <Row pt={6}>
            <Column
              span={12}
              spanXs={12}
              spanSm={12}
              spanMd={12}
              textAlign='center'
            >
              <OrderTrackingLink
                tenant={tenant}
                locale={locale}
              />
            </Column>
          </Row>
        </Column>
      </Row>
    </GridContainer>
  )
}

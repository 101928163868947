export const formatLocale = (locale: string) => {
  if (!locale || typeof locale !== 'string') return ''

  const parts = locale.split('-')
  if (parts.length !== 2) return locale // Return as-is if not in expected format

  const language = parts[0].toLowerCase()
  const region = parts[1].toUpperCase()

  return `${language}-${region}`
}

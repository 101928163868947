import React from 'react'
import { Box, Icon, Typography, Link } from '@vp/swan'
import { useTranslation } from 'react-i18next'
import AnalyticsTracking from '../analytics/analytics'

interface OrderTrackingLinkProps {
  locale: string;
  tenant: string;
}
export const OrderTrackingLink = (props: OrderTrackingLinkProps) => {
  const { locale, tenant } = props
  const lng = locale.toLowerCase().replace('-', '_')
  const { t } = useTranslation(tenant)
  return (
    <>
      <Box display={{ xs: 'none', sm: 'block' }}>
        <div className='hc_order_link_container'>
          <Icon mr={3} iconType='orders' />
          <Typography component='span' fontSize='standard' marginRight={3} fontWeight='normal' textColor='standard'>
            {t(`${tenant}:header.orderTracking.need_help_tracking_order`, { lng })}
          </Typography>
          <Link
            href={t(`${tenant}:header.orderTracking.link`, { lng })}
            className='text-size-5 order_tracking_link'
            target='_blank'
            data-section='Custom Header'
            data-translation='OrderTrack' rel='noreferrer'
            onClick={() => { AnalyticsTracking.trackNavigationClicked('Navigation Clicked', { destinationPagePath: t(`${tenant}:header.orderTracking.link`, { lng }), pageZone: 'CustomHeader', ctaValue: 'OrderTrack' }) }}
          >
            {t(`${tenant}:header.orderTracking.find_and_track`, { lng })}
          </Link>
        </div>
      </Box>
      <Box display={{ lg: 'none', md: 'none', sm: 'none' }}>
        <div>
          <Icon mr={3} iconType='orders' />
          <Link
            href={t(`${tenant}:header.orderTracking.link`, { lng })}
            className='text-size-5 order_tracking_link_xs'
            target='_blank'
            data-section='Custom Header'
            data-translation='OrderTrack' rel='noreferrer'
            onClick={() => { AnalyticsTracking.trackNavigationClicked('Navigation Clicked', { destinationPagePath: t(`${tenant}:header.orderTracking.link`, { lng }), pageZone: 'CustomHeader', ctaValue: 'OrderTrack' }) }}
          >
            {t(`${tenant}:header.orderTracking.find_and_track`, { lng })}
          </Link>
        </div>
      </Box>
    </>
  )
}

type NavigationDetails = {
  destinationPagePath: string;
  pageZone: string;
  ctaValue: string;
  pageName?: string;
}

export default class AnalyticsTracking {
  static trackNavigationClicked (eventName: string, details: NavigationDetails): void {
    AnalyticsTracking.sendEvent(() => {
      const sourcePagePath = window.location.pathname
      const eventDetail = `${sourcePagePath};${details.destinationPagePath};${details.pageZone};${details.ctaValue}`
      window.tracking.track('Navigation Clicked', {
        category: 'Help Center',
        label: `Content:${details.pageZone}`,
        eventDetail,
        navigationDetail: details.ctaValue,
      })
    }, 0)
  }

  static sendEvent (event: () => void, breaker: number): void {
    if (window && window.tracking && window.tracking.track) {
      event()
    } else if (breaker > 20) { // after 20 seconds this had better exist
      // Logging can be added here if needed
    } else {
      setTimeout(() => this.sendEvent(event, breaker + 1), 1000)
    }
  }
}

import { IdentityProvider, useSwanStyleKeys, useUserContext } from '@vp/ubik-context'
import { App } from './App'
import './modules/localization/i18n'
import { HelpCenterUrls } from './types/urls.type'
import LocalizationProvider from './components/providers/LocalizationProvider'
export interface Props {
  helpCenterUrls: HelpCenterUrls
  localization: Record<string, any>
}

export const createAuthConfig = (culture: string, isDevelopment = false) => ({
  culture,
  developmentMode: isDevelopment,
  options: {
    requireSession: true,
    customText: 'Sign in to see your account',
  },
})

export const Fragment = (props: Props) => {
  const { locale, tenant = 'vistaprint' } = useUserContext()
  useSwanStyleKeys(['core'])
  return (
    <LocalizationProvider locale={locale} localizationData={props.localization}>
      <IdentityProvider auth={createAuthConfig(locale || 'en-us')}>
        <App locale={locale} tenant={tenant} helpCenterUrls={props.helpCenterUrls} />
      </IdentityProvider>
    </LocalizationProvider>
  )
}

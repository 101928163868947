export const formatUrl = (url: string): string => {
  if (typeof url !== 'string') return ''

  try {
    const parsedUrl = new URL(url)
    return `${parsedUrl.origin}${parsedUrl.pathname}`
  } catch (error) {
    console.error('Invalid URL:', error)
    return url
  }
}
